import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
Matigia d.o.o.    <br></br>    
Nedakusi bb <br></br>
Bijelo Polje <br></br>
Montenegro <br></br>
mladen@matigia.com <br></br>
+38267446594 <br></br>
</p>
      </header>
    </div>
  );
}

export default App;
